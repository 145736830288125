<template>
    <a-card style="background-color: #fff;">
        <a-tabs default-active-key="" @change="callback" v-model="fhStatus">
            <a-tab-pane key="" tab="全部">
            </a-tab-pane>
            <a-tab-pane key="1" tab="未发货">
            </a-tab-pane>
            <a-tab-pane key="2" tab="配货中">
            </a-tab-pane>
        </a-tabs>
        <div>
            <a-form layout="horizontal">
                <div :class="advanced ? null : 'fold'">
                    <a-row v-if="!fhStatus">
                        <a-col :md="8" :sm="24" style="max-width: 350px;">
                            <a-form-item label="订单号" :labelCol="{ span: 5 }" :wrapperCol="{ span: 17, offset: 1 }">
                                <a-input placeholder="请输入订单号" v-model="queryData.code"></a-input>
                            </a-form-item>
                        </a-col>
                        <a-col :md="8" :sm="24" style="max-width: 350px;">
                            <a-form-item label="商品名称" :labelCol="{ span: 5 }" :wrapperCol="{ span: 17, offset: 1 }">
                                <a-input placeholder="请输入商品名称" v-model="queryData.productName"></a-input>
                            </a-form-item>
                        </a-col>
                        <a-col :md="8" :sm="24" style="max-width: 350px;">
                            <a-form-item label="订单状态" :labelCol="{ span: 5 }" :wrapperCol="{ span: 17, offset: 1 }">
                                <a-select v-model="queryData.orderStatus" placeholder="请选择状态" option-label-prop="label"
                                    allowClear>
                                    <a-select-option
                                        v-for="item in [{ name: '待收货', id: 1 }, { name: '已取消', id: 2 }, { name: '已完成', id: 3 }]"
                                        :key="item.id" :value="item.id" :label="item.name">
                                        {{ item.name }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                        <!-- <a-col :md="8" :sm="24" style="max-width: 350px;">
                            <a-form-item label="子订单状态" :labelCol="{ span: 5 }" :wrapperCol="{ span: 17, offset: 1 }">
                                <a-select v-model="queryData.orderDetailStatus" placeholder="请选择状态" option-label-prop="label"
                                    allowClear>
                                    <a-select-option
                                        v-for="item in [{ name: '正常', id: 1 }, { name: '关闭', id: 2 }]"
                                        :key="item.id" :value="item.id" :label="item.name">
                                        {{ item.name }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col> -->
                        <a-col :md="8" :sm="24" style="max-width: 350px;">
                            <a-form-item label="发货状态" :labelCol="{ span: 5 }" :wrapperCol="{ span: 17, offset: 1 }">
                                <a-select v-model="queryData.fhStatus" placeholder="请选择状态" option-label-prop="label"
                                    allowClear>
                                    <a-select-option
                                        v-for="item in [{ name: '未发货', id: 0 }, { name: '配货中', id: 2 }, { name: '已发货', id: 1 }]"
                                        :key="item.id" :value="item.id" :label="item.name">
                                        {{ item.name }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                        <a-col :md="8" :sm="24" style="max-width: 350px;">
                            <a-form-item label="收货手机号" :labelCol="{ span: 5 }" :wrapperCol="{ span: 17, offset: 1 }">
                                <a-input placeholder="请输入收货手机号" v-model="queryData.receiveUserMobile"></a-input>
                            </a-form-item>
                        </a-col>
                        <a-col :md="8" :sm="24" style="max-width: 350px;">
                            <a-form-item label="物流单号" :labelCol="{ span: 5 }" :wrapperCol="{ span: 17, offset: 1 }">
                                <a-input placeholder="请输入物流单号" v-model="queryData.expressCode"></a-input>
                            </a-form-item>
                        </a-col>
                        <a-col :md="8" :sm="24" style="max-width: 350px;">
                            <a-form-item label="下单时间" :labelCol="{ span: 5 }" :wrapperCol="{ span: 17, offset: 1 }">
                                <a-range-picker :value="dateValue" :ranges="ranges" @change="dateChange" />
                            </a-form-item>
                        </a-col>
                        <a-col :md="8" :sm="24" style="max-width: 350px;">
                            <a-form-item label="发货时间" :labelCol="{ span: 5 }" :wrapperCol="{ span: 17, offset: 1 }">
                                <a-range-picker :value="expressDateValue" :ranges="ranges"
                                    @change="expressDateChange" />
                            </a-form-item>
                        </a-col>
                        <a-col :md="8" :sm="24" style="max-width: 350px;">
                            <a-button @click="reset" style="margin-left: 10px;">重置</a-button>
                            <a-button type="primary" style="margin-top: 4px;margin-left: 10px;"
                                @click="search">查询</a-button>
                        </a-col>
                    </a-row>

                    <a-row v-if="fhStatus">
                        <a-col :md="8" :sm="24" style="max-width: 350px;">
                            <a-form-item label="下单时间" :labelCol="{ span: 5 }" :wrapperCol="{ span: 17, offset: 1 }">
                                <a-range-picker :value="dateValue" :ranges="ranges" @change="dateChange" />
                            </a-form-item>
                        </a-col>
                        <a-col :md="8" :sm="24" style="max-width: 350px;">
                            <a-button @click="reset" style="margin-left: 10px;">重置</a-button>
                            <a-button type="primary" style="margin-top: 4px;margin-left: 10px;"
                                @click="search">查询</a-button>
                        </a-col>
                    </a-row>

                </div>
            </a-form>
        </div>
        <div>
            <a-button v-if="fhStatus == 2" type="primary" @click="fhAllOpen = true">批量添加物流信息</a-button>
            <a-button v-if="fhStatus != 2" type="primary" @click="exportChange(1)" :loading="exportLoading" style="margin-right:10px">导出选中订单</a-button>
            <a-button v-if="fhStatus != 2" type="primary" @click="exportChange" :loading="exportLoading">导出全部订单</a-button>
            <div style="margin: 10px 0;font-size: 15px;margin-left: 10px;">显示第 {{ total > 0 ? 1 : 0 }} 至 {{
            queryData.size > total ? total : queryData.size }} 项结果，共 {{ total }} 项</div>
            <a-table :scroll="{ x: 2000 }" bordered :columns="columns" :dataSource="dataSource" :loading="tableLoading"
                size="small" :pagination="false">
                <!-- :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" -->

                <template slot="customTitle" v-if="fhStatus != 2">
                    本页<a-checkbox @change="selectAllChange" v-model="checkedAll" />
                </template>
                <template slot="expressCode" slot-scope="record,text">
                    <span v-if="text.expressCode && text.expressCompany" style="color:green">已发货</span>
                    <span v-else style="color:red">未发货</span>
                </template>
                <template slot="expressCompany" slot-scope="record,text">
                    <span @click="showExporess(text.ordersDetailsId)" style="color:green;cursor: pointer;"
                        v-if="text.expressCode && text.expressCompany">{{ text.expressCompany
                        }}:
                        {{ text.expressCode }}</span>
                </template>

                <template slot="expressTime" slot-scope="record">
                    {{ record ? formatDateMin(record) : '' }}
                </template>
                <template slot="createTime" slot-scope="record">{{ formatDateMin(record) }}</template>
                <div slot="action" slot-scope="record">
                    <!-- 主订单待收货 子订单状态正常 -->
                    <a @click="showModal(0, record)" v-if="record.status == 1 && record.sonStatus == 1"
                        style="margin-left: 10px;">物流信息</a>
                </div>
                <template slot="imagePath" slot-scope="record">
                    <img :src="record" style="width: 60px;max-height: 60px;" alt="">
                </template>
            </a-table>
            <div class="page-box">
                <a-pagination :total="total" :current="queryData.page + 1" :page-size-options="pageSizeOptions"
                    :pageSize="queryData.size" @change="handlePageChange" @showSizeChange="onShowSizeChange"
                    show-size-changer />
            </div>
        </div>

        <a-modal v-model="openKC" title="变更库存" @ok="handleCk">
            <p>现有库存：{{ oldstock }}个</p>
            <p>
                变更为 <a-input v-model="stock" style="width:60px;" size="small" /> 个
            </p>
        </a-modal>

        <a-modal v-model="openActionLog" title="操作日志" :footer="null">
            <a-table bordered :columns="columnsActionLog" :dataSource="dataSourceActionLog"
                :loading="tableLogActionLoading" :pagination="false">
                <template slot="createTime" slot-scope="record">{{ formatDateMin(record) }}</template>
            </a-table>
            <div class="page-box">
                <a-pagination :total="logActionTotal" :current="logActionQuery.page + 1"
                    :page-size-options="pageSizeOptions" :pageSize="logActionQuery.size" @change="handlePageChange"
                    @showSizeChange="onShowSizeChange" show-size-changer />
            </div>
        </a-modal>

        <a-modal v-model="fhOpen" title="填写物流信息" @ok="handlefh">
            <a-form-model>
                <a-form-model-item label="物流公司" required style="margin:0 20px;">
                    <a-select placeholder="请选择物流公司" v-model="fhObj.type">
                        <a-select-option :value="item.code" v-for="item in expList" :key="item.id">{{ item.name
                            }}</a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="物流单号" required style="margin:0 20px;">
                    <a-input v-model="fhObj.code" placeholder="请输入物流单号" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>

        <!-- 批量添加物流信息 -->
        <a-modal v-model="fhAllOpen" title="批量添加物流信息" @ok="handlefhAll" @cancel="handlefhCancel">
            <p style="color:red">温馨提示：请确保您上传的Excel中的订单物流公司相同。已经发货的订单再次上传，物流单号将会覆盖。</p>
            <a-form-model>
                <a-form-model-item label="上传方式" required style="margin:0 20px;">
                    <a-select placeholder="请选择上传方式" v-model="uploadType">
                        <a-select-option v-for="item in [{ name: '覆盖', id: 1 }]" :key="item.id" :value="item.id"
                            :label="item.name">
                            {{ item.name }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="物流公司" required style="margin:0 20px;">
                    <a-select placeholder="请选择物流公司" v-model="expressCompanyId">
                        <a-select-option :value="item.id" v-for="item in expList" :key="item.id">
                            {{ item.name }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="文件上传" required style="margin:0 20px;">
                    <a-upload :file-list="fileList" accept=".xls,.xlsx" @change="importFileChange" :multiple="false"
                        :data="postData" :before-upload="beforeUpload" :action="qiNiuAction">
                        <a-button v-if="fileList.length == 0"> 上传文件 </a-button>
                    </a-upload>
                </a-form-model-item>

            </a-form-model>
        </a-modal>

        <a-modal v-model="downOpen" title="取消订单" @ok="handleDown">
            <a-textarea v-model="reason" placeholder="请输入取消原因"></a-textarea>
        </a-modal>

        <a-modal v-model="exporessShow" title="查看物流信息" @ok="exporessShow = false; exporessList = []" :width="800"
            :footer="null">
            <p v-for="(item, index) in exporessList" :key="index">
                [ <span>{{ item.time }}</span> ]<span>{{ item.context }}</span>
            </p>
        </a-modal>


    </a-card>
</template>

<script>
import moment from 'moment';
import 'moment/locale/zh-cn';
import { request } from '@/utils/request'
export default {
    name: 'QueryList',
    data() {
        return {
            moment,
            advanced: true,
            columns: [
                {
                    slots: { title: 'customTitle' },
                    dataIndex: 'customTitle',
                    customRender: (value, row, index) => {
                        const obj = {
                            children: <a-checkbox
                                checked={this.selectedRowKeys.includes(row.id)}
                                onChange={() => this.handleSelect(row)}
                            />,  // 格式化后的值显示
                            attrs: {},
                        };
                        let result = this.findDuplicateIds(this.dataSource);
                        result.forEach(item => {
                            if (item.id == row.id) {
                                obj.attrs.rowSpan = 0;
                                if (index == item.indexes[0]) {
                                    obj.attrs.rowSpan = item.indexes.length;
                                }
                            }
                        })
                        return obj;
                    },
                    align: 'center'
                },
                // { title: '序号', width: 80, customRender: (text, record, index) => index + 1, align: 'center' },
                {
                    title: '下单时间',
                    dataIndex: 'orderCreateTime',
                    align: 'center',
                    // ellipsis: true,
                    customRender: (value, row, index) => {
                        const obj = {
                            children: <span>{this.formatDateMin(value)}</span>,  // 格式化后的值显示
                            attrs: {},
                        };
                        let result = this.findDuplicateIds(this.dataSource);
                        result.forEach(item => {
                            if (item.id == row.id) {
                                obj.attrs.rowSpan = 0;
                                if (index == item.indexes[0]) {
                                    obj.attrs.rowSpan = item.indexes.length;
                                }
                            }
                        })
                        return obj;
                    },
                    width: 100,
                },
                {
                    title: '订单号',
                    dataIndex: 'code',
                    customRender: (value, row, index) => {
                        const obj = {
                            children: value,
                            attrs: {},
                        };
                        let result = this.findDuplicateIds(this.dataSource);
                        result.forEach(item => {
                            if (item.id == row.id) {
                                obj.attrs.rowSpan = 0;
                                if (index == item.indexes[0]) {
                                    obj.attrs.rowSpan = item.indexes.length;
                                }
                            }
                        })
                        return obj;
                    },
                    width: 120,
                    align: 'center',
                },
                {
                    title: '收货信息',
                    dataIndex: 'address',
                    customRender: (value, row, index) => {
                        console.log(value.city)
                        const obj = {
                            children: <div>
                                <div>收货人: {value.name}</div>
                                <div>收货人手机号: {value.mobile}</div>
                                <div>收货地址: {value.province}{value.city}{value.area}{value.town}{value.addressDetails}</div>
                            </div>,
                            attrs: {},
                        };
                        let result = this.findDuplicateIds(this.dataSource);
                        result.forEach(item => {
                            if (item.id == row.id) {
                                obj.attrs.rowSpan = 0;
                                if (index == item.indexes[0]) {
                                    obj.attrs.rowSpan = item.indexes.length;
                                }
                            }
                        })
                        return obj;
                    },
                    width: 220,
                    align: 'center',
                },
                {
                    title: '订单状态',
                    dataIndex: 'statusStr',
                    customRender: (value, row, index) => {
                        const obj = {
                            children: value,
                            attrs: {},
                        };
                        let result = this.findDuplicateIds(this.dataSource);
                        result.forEach(item => {
                            if (item.id == row.id) {
                                obj.attrs.rowSpan = 0;
                                if (index == item.indexes[0]) {
                                    obj.attrs.rowSpan = item.indexes.length;
                                }
                            }
                        })
                        return obj;
                    },
                    width: 80,
                    align: 'center',
                },
                {
                    title: '商品总数量',
                    dataIndex: 'totalQuantity',
                    customRender: (value, row, index) => {
                        const obj = {
                            children: value,
                            attrs: {},
                        };
                        let result = this.findDuplicateIds(this.dataSource);
                        result.forEach(item => {
                            if (item.id == row.id) {
                                obj.attrs.rowSpan = 0;
                                if (index == item.indexes[0]) {
                                    obj.attrs.rowSpan = item.indexes.length;
                                }
                            }
                        })
                        return obj;
                    },
                    width: 90,
                    align: 'center',
                },
                {
                    title: '订单结算总价',
                    dataIndex: 'totalPrice',
                    customRender: (value, row, index) => {
                        const obj = {
                            children: value,
                            attrs: {},
                        };
                        let result = this.findDuplicateIds(this.dataSource);
                        result.forEach(item => {
                            if (item.id == row.id) {
                                obj.attrs.rowSpan = 0;
                                if (index == item.indexes[0]) {
                                    obj.attrs.rowSpan = item.indexes.length;
                                }
                            }
                        })
                        return obj;
                    },
                    width: 110,
                    align: 'center',
                },

                // { title: '下单时间', width: 160, dataIndex: 'createTime', align: 'center', scopedSlots: { customRender: 'createTime' } },
                // { title: '订单号', dataIndex: 'code', align: 'center', width: 220 },
                // { title: '收货信息', dataIndex: 'address', align: 'center', width: 300, scopedSlots: { customRender: 'address' } },
                { title: '商品图片', dataIndex: 'imagePath', align: 'center', width: 100, scopedSlots: { customRender: 'imagePath' } },
                { title: '商品名称', dataIndex: 'goodsName', align: 'center', width: 300, },
                { title: '规格', dataIndex: 'goodsSpecs', align: 'center', width: 300, },
                { title: '商品数量', dataIndex: 'quantity', align: 'center' },
                { title: '结算价', dataIndex: 'price', align: 'center' },
                { title: '子订单状态', dataIndex: 'sonStatusStr', align: 'center' },
                { title: '发货状态', dataIndex: 'fhStatusStr', align: 'center' },
                { title: '物流单号', dataIndex: 'expressCompany', align: 'center', scopedSlots: { customRender: 'expressCompany' } },
                { title: '发货时间', dataIndex: 'expressTime', align: 'center', scopedSlots: { customRender: 'expressTime' } },
                { title: '操作', width: 120, align: 'center', fixed: "right", scopedSlots: { customRender: 'action' } },
            ],
            dataSource: [],
            current: 1,
            dateValue: '',
            expressDateValue: '',
            total: 0,
            // 筛选
            queryData: {
                page: 0,
                size: 10,
                orderStatus: undefined,
                fhStatus: undefined,
                receiveUserMobile: undefined,
                orderDetailStatus: undefined
            },
            pageSizeOptions: ['10', '30', '40', '50', '100', '500', '1000'],
            tableLoading: false,

            currentPage: 1,

            ranges: {
                '今天': [moment(), moment()],
                '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                '当月': [moment().startOf('month'), moment().endOf('month')],
                '上月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            },
            isModalVisible: false,
            confirmLoading: false,
            formData: {},
            categoryList: [{ id: 0, name: '草稿' }, { id: 1, name: '审核中' }, { id: 2, name: '审核通过' }, { id: 3, name: '审核未通过' }],
            userInfo: {},
            // 日志
            logQuery: {
                page: 0,
                size: 10
            },
            columnsLog: [],
            dataSourceLog: [
                { title: '审核状态', dataIndex: 'typeDesc', align: 'center', },
                { title: '操作时间', dataIndex: 'createTime', align: 'center', scopedSlots: { customRender: 'createTime' } },
                { title: '操作人', dataIndex: 'skuCount', align: 'center', },
                { title: '反馈详情', dataIndex: 'skuCount', align: 'center', },
            ],
            tableLogLoading: false,
            openLog: false,
            logTotal: 0,

            // 操作日志
            openActionLog: false,
            dataSourceActionLog: [],
            columnsActionLog: [],
            tableLogActionLoading: false,
            logActionQuery: {
                page: 0,
                size: 10
            },
            logActionTotal: 0,

            // 库存
            openKC: false,
            oldstock: 0,
            stock: "",
            editId: "",


            // 下架
            downOpen: false,
            reason: "",

            // 品类
            categoryOptions: [{ value: '1', label: '商城', isLeaf: false }],
            expList: [],
            fhOpen: false,
            actionObj: {},
            fhObj: {},
            // 批量添加
            fhAllOpen: false,
            // 选中的列
            selectRow: [],
            selectedRowKeys: [],
            // 上传物流方式
            uploadType: 1,
            // 物流公司id
            expressCompanyId: undefined,
            // 文件
            fileData: {},
            fileList: [],
            qiNiuAction: 'https://upload-z1.qiniup.com',
            getQiniuTokenIng: false,
            postData: {
                token: ''
            },
            exportLoading: false,
            fhStatus: "",


            exporessShow: false,
            exporessList: [],
            checkedAll: false
        }
    },
    mounted() {
        this.getData()
        this.getExpList();//获取快递公司
        this.getQiniuToken();//qiniu
    },
    methods: {
        selectAllChange(e) {
            let checked = e.target.checked
            if (checked) {
                this.checkedAll = true;
                console.log(this.selectedRowKeys, 'this.selectedRowKeys')
                this.selectedRowKeys = []
                this.dataSource.forEach(item => {
                    this.selectedRowKeys.push(item.id)
                })
            } else {
                this.checkedAll = false;
                this.selectedRowKeys = []
            }
        },
        handleSelect(record) {
            const index = this.selectedRowKeys.indexOf(record.id);
            if (index > -1) {
                // 如果该 ID 已被勾选，则取消勾选
                this.selectedRowKeys = this.selectedRowKeys.filter(id => id !== record.id);
                this.checkedAll = false
            } else {
                // 如果该 ID 没有被勾选，则勾选所有相同 ID 的行
                const sameIdRows = this.dataSource.filter(row => row.id === record.id);
                const sameIdKeys = sameIdRows.map(row => row.id);
                this.selectedRowKeys = [...new Set([...this.selectedRowKeys, ...sameIdKeys])];
            }
        },
        showExporess(id) {
            this.exporessShow = true;
            this.exporessList = []
            request(process.env.VUE_APP_API_BASE_URL + 'srm/orders/getExpress/' + id, 'get').then((res) => {
                if (res.data.code == 200) {
                    this.exporessList = res.data.data.orderExpress.data;
                }
            })
        },
        beforeUpload(file) {
            const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                file.type === 'application/vnd.ms-excel';
            if (!isExcel) {
                this.$message.error('只能上传 Excel 文件!');
            }
            return isExcel;  // 返回 false 会阻止上传
        },
        getQiniuToken() {
            if (!this.getQiniuTokenIng) {
                this.getQiniuTokenIng = true
                this.loading = true
                this.loadingText = 'token加载中'
                request(process.env.VUE_APP_API_BASE_URL + 'srm/qiniu/token/yjhp-hb', 'get').then((res) => {
                    if (res.data.code == 200) {
                        this.postData.token = res.data.data
                        this.getQiniuTokenIng = false
                    }
                })
            }
        },
        importFileChange(e) {
            console.log(e, 'e')
            this.fileObj = e;
            this.fileList = [...e.fileList];
            // let formData = new FormData();
            // formData.append('file', e.file.originFileObj);
            // console.log(formData,'formData')
        },
        onSelectChange(selectedRowKeys, row) {
            this.selectedRowKeys = selectedRowKeys;
            this.selectRow = row
        },
        // onSelectChange(selectedRowKeys, row) {
        //     this.selectedRowKeys = selectedRowKeys;
        //     this.selectRow = row
        // },
        // 批量导出
        exportChange() {
            let ids = []
            if (this.selectedRowKeys.length > 0) {
                this.selectedRowKeys.forEach(item => {
                    ids.push(item)
                })
            }
            // if(ids.length == 0 && type) {
            //     this.$message.error('请选择数据')
            //     return false;
            // }
            this.exportLoading = true;
            request(process.env.VUE_APP_API_BASE_URL + 'srm/orders/export', 'post', {
                queryOrderParm: {
                    ids: ids,
                    ...this.queryData
                }
            }).then(res => {
                if (res.data.code == 200) {
                    this.exportLoading = false;
                    this.$message.success('导出成功！请前往个人中心下载！')
                    this.selectedRowKeys = []
                    this.selectRow = []
                } else {
                    this.$message.error(res.data.message)
                }

                // this.expList = res.data.data
            })
        },
        findDuplicateIds(array) {
            const idMap = {};
            const duplicates = [];

            array.forEach((item, index) => {
                if (idMap[item.id] === undefined) {
                    idMap[item.id] = index;
                } else {
                    if (!duplicates.some(duplicate => duplicate.id === item.id)) {
                        duplicates.push({ id: item.id, indexes: [idMap[item.id], index] });
                    } else {
                        const existingDuplicate = duplicates.find(duplicate => duplicate.id === item.id);
                        existingDuplicate.indexes.push(index);
                    }
                }
            });

            return duplicates;
        },
        getExpList() {
            request(process.env.VUE_APP_API_BASE_URL + 'srm/express/company/list', 'get',).then(res => {
                this.expList = res.data.data
            })
        },
        categoryChange(value) {
            this.queryData.classifyList = value;
        },
        getCategoryList(value) {
            let id = value[value.length - 1].value
            let url = process.env.VUE_APP_API_BASE_URL + 'srm/classify/list/' + id + '/0'
            if (id != 1) {
                url = process.env.VUE_APP_API_BASE_URL + 'srm/classify/list/' + 1 + '/' + id
            }
            request(url, 'get').then((res) => {
                let data = res.data.data;
                data.forEach(item => {
                    item.value = item.id
                    item.label = item.name
                    if (item.level < 3) {
                        item.isLeaf = false
                    }
                })
                if (value.length != 3) {
                    if (id == 1) {
                        this.categoryOptions.forEach(item => {
                            if (item.value == id) {
                                item.children = data;
                            }
                        })
                    } else {
                        this.categoryOptions[0].children.forEach(item => {
                            if (item.value == id) {
                                item.children = data;
                            }
                        })
                    }
                } else {
                    this.categoryOptions[0].children.forEach(item => {
                        if (item.id == value[1].id) {
                            item.children.forEach(it => {
                                if (it.id == value[2].id) {
                                    it.children = data;
                                }
                            })
                        }
                    })
                }

            })
        },
        handleCk() {
            if (!this.stock) {
                this.$message.error('请输入库存数量！')
                return false
            }
            request(process.env.VUE_APP_API_BASE_URL + 'srm/goodsSpec/editStock', 'post', { id: this.editId, stock: this.stock - 0 }).then(res => {
                if (res.data.code == 200) {
                    this.openKC = false;
                    this.$message.success('修改成功！')
                    this.getData()
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        callback(e) {
            this.selectedRowKeys = [];
            this.selectRow = []
            this.queryData = {
                page: 0,
                size: 10,
            }
            if(e == 1) {
                this.queryData = {
                    page: 0,
                    size: 10,
                    fhStatus:0,
                    orderDetailStatus:1
                }
            }
            if(e == 2) {
                this.queryData = {
                    page: 0,
                    size: 10,
                    fhStatus:2,
                    orderDetailStatus:1
                }
            }
            if(!e) {
                delete this.queryData.fhStatus
                delete this.queryData.orderDetailStatus
            }

            if (this.queryData.fhStatus == 2) {
                this.columns =[
                    {
                        title: '下单时间',
                        dataIndex: 'orderCreateTime',
                        align: 'center',
                        // ellipsis: true,
                        customRender: (value, row, index) => {
                            const obj = {
                                children: <span>{this.formatDateMin(value)}</span>,  // 格式化后的值显示
                                attrs: {},
                            };
                            let result = this.findDuplicateIds(this.dataSource);
                            result.forEach(item => {
                                if (item.id == row.id) {
                                    obj.attrs.rowSpan = 0;
                                    if (index == item.indexes[0]) {
                                        obj.attrs.rowSpan = item.indexes.length;
                                    }
                                }
                            })
                            return obj;
                        },
                        width: 100,
                    },
                    {
                        title: '订单号',
                        dataIndex: 'code',
                        customRender: (value, row, index) => {
                            const obj = {
                                children: value,
                                attrs: {},
                            };
                            let result = this.findDuplicateIds(this.dataSource);
                            result.forEach(item => {
                                if (item.id == row.id) {
                                    obj.attrs.rowSpan = 0;
                                    if (index == item.indexes[0]) {
                                        obj.attrs.rowSpan = item.indexes.length;
                                    }
                                }
                            })
                            return obj;
                        },
                        width: 120,
                        align: 'center',
                    },
                    {
                        title: '收货信息',
                        dataIndex: 'address',
                        customRender: (value, row, index) => {
                            console.log(value.city)
                            const obj = {
                                children: <div>
                                    <div>收货人: {value.name}</div>
                                    <div>收货人手机号: {value.mobile}</div>
                                    <div>收货地址: {value.province}{value.city}{value.area}{value.addressDetails}</div>
                                </div>,
                                attrs: {},
                            };
                            let result = this.findDuplicateIds(this.dataSource);
                            result.forEach(item => {
                                if (item.id == row.id) {
                                    obj.attrs.rowSpan = 0;
                                    if (index == item.indexes[0]) {
                                        obj.attrs.rowSpan = item.indexes.length;
                                    }
                                }
                            })
                            return obj;
                        },
                        width: 220,
                        align: 'center',
                    },
                    {
                        title: '订单状态',
                        dataIndex: 'statusStr',
                        customRender: (value, row, index) => {
                            const obj = {
                                children: value,
                                attrs: {},
                            };
                            let result = this.findDuplicateIds(this.dataSource);
                            result.forEach(item => {
                                if (item.id == row.id) {
                                    obj.attrs.rowSpan = 0;
                                    if (index == item.indexes[0]) {
                                        obj.attrs.rowSpan = item.indexes.length;
                                    }
                                }
                            })
                            return obj;
                        },
                        width: 80,
                        align: 'center',
                    },
                    {
                        title: '商品总数量',
                        dataIndex: 'totalQuantity',
                        customRender: (value, row, index) => {
                            const obj = {
                                children: value,
                                attrs: {},
                            };
                            let result = this.findDuplicateIds(this.dataSource);
                            result.forEach(item => {
                                if (item.id == row.id) {
                                    obj.attrs.rowSpan = 0;
                                    if (index == item.indexes[0]) {
                                        obj.attrs.rowSpan = item.indexes.length;
                                    }
                                }
                            })
                            return obj;
                        },
                        width: 90,
                        align: 'center',
                    },
                    {
                        title: '订单结算总价',
                        dataIndex: 'totalPrice',
                        customRender: (value, row, index) => {
                            const obj = {
                                children: value,
                                attrs: {},
                            };
                            let result = this.findDuplicateIds(this.dataSource);
                            result.forEach(item => {
                                if (item.id == row.id) {
                                    obj.attrs.rowSpan = 0;
                                    if (index == item.indexes[0]) {
                                        obj.attrs.rowSpan = item.indexes.length;
                                    }
                                }
                            })
                            return obj;
                        },
                        width: 110,
                        align: 'center',
                    },
                    { title: '商品图片', dataIndex: 'imagePath', align: 'center', width: 100, scopedSlots: { customRender: 'imagePath' } },
                    { title: '商品名称', dataIndex: 'goodsName', align: 'center', width: 300, },
                    { title: '规格', dataIndex: 'goodsSpecs', align: 'center', width: 300, },
                    { title: '商品数量', dataIndex: 'quantity', align: 'center' },
                    { title: '结算价', dataIndex: 'price', align: 'center' },
                    { title: '子订单状态', dataIndex: 'sonStatusStr', align: 'center' },
                    { title: '发货状态', dataIndex: 'fhStatusStr', align: 'center' },
                    { title: '物流单号', dataIndex: 'expressCompany', align: 'center', scopedSlots: { customRender: 'expressCompany' } },
                    { title: '发货时间', dataIndex: 'expressTime', align: 'center', scopedSlots: { customRender: 'expressTime' } },
                    { title: '操作', width: 120, align: 'center', fixed: "right", scopedSlots: { customRender: 'action' } },
                ]
            } else {
                this.columns = [
                    {
                        slots: { title: 'customTitle' },
                        dataIndex: 'customTitle',
                        customRender: (value, row, index) => {
                            const obj = {
                                children: <a-checkbox
                                    checked={this.selectedRowKeys.includes(row.id)}
                                    onChange={() => this.handleSelect(row)}
                                />,  // 格式化后的值显示
                                attrs: {},
                            };
                            let result = this.findDuplicateIds(this.dataSource);
                            result.forEach(item => {
                                if (item.id == row.id) {
                                    obj.attrs.rowSpan = 0;
                                    if (index == item.indexes[0]) {
                                        obj.attrs.rowSpan = item.indexes.length;
                                    }
                                }
                            })
                            return obj;
                        },
                        align: 'center'
                    },
                    {
                        title: '下单时间',
                        dataIndex: 'orderCreateTime',
                        align: 'center',
                        // ellipsis: true,
                        customRender: (value, row, index) => {
                            const obj = {
                                children: <span>{this.formatDateMin(value)}</span>,  // 格式化后的值显示
                                attrs: {},
                            };
                            let result = this.findDuplicateIds(this.dataSource);
                            result.forEach(item => {
                                if (item.id == row.id) {
                                    obj.attrs.rowSpan = 0;
                                    if (index == item.indexes[0]) {
                                        obj.attrs.rowSpan = item.indexes.length;
                                    }
                                }
                            })
                            return obj;
                        },
                        width: 100,
                    },
                    {
                        title: '订单号',
                        dataIndex: 'code',
                        customRender: (value, row, index) => {
                            const obj = {
                                children: value,
                                attrs: {},
                            };
                            let result = this.findDuplicateIds(this.dataSource);
                            result.forEach(item => {
                                if (item.id == row.id) {
                                    obj.attrs.rowSpan = 0;
                                    if (index == item.indexes[0]) {
                                        obj.attrs.rowSpan = item.indexes.length;
                                    }
                                }
                            })
                            return obj;
                        },
                        width: 120,
                        align: 'center',
                    },
                    {
                        title: '收货信息',
                        dataIndex: 'address',
                        customRender: (value, row, index) => {
                            console.log(value.city)
                            const obj = {
                                children: <div>
                                    <div>收货人: {value.name}</div>
                                    <div>收货人手机号: {value.mobile}</div>
                                    <div>收货地址: {value.province}{value.city}{value.area}{value.addressDetails}</div>
                                </div>,
                                attrs: {},
                            };
                            let result = this.findDuplicateIds(this.dataSource);
                            result.forEach(item => {
                                if (item.id == row.id) {
                                    obj.attrs.rowSpan = 0;
                                    if (index == item.indexes[0]) {
                                        obj.attrs.rowSpan = item.indexes.length;
                                    }
                                }
                            })
                            return obj;
                        },
                        width: 220,
                        align: 'center',
                    },
                    {
                        title: '订单状态',
                        dataIndex: 'statusStr',
                        customRender: (value, row, index) => {
                            const obj = {
                                children: value,
                                attrs: {},
                            };
                            let result = this.findDuplicateIds(this.dataSource);
                            result.forEach(item => {
                                if (item.id == row.id) {
                                    obj.attrs.rowSpan = 0;
                                    if (index == item.indexes[0]) {
                                        obj.attrs.rowSpan = item.indexes.length;
                                    }
                                }
                            })
                            return obj;
                        },
                        width: 80,
                        align: 'center',
                    },
                    {
                        title: '商品总数量',
                        dataIndex: 'totalQuantity',
                        customRender: (value, row, index) => {
                            const obj = {
                                children: value,
                                attrs: {},
                            };
                            let result = this.findDuplicateIds(this.dataSource);
                            result.forEach(item => {
                                if (item.id == row.id) {
                                    obj.attrs.rowSpan = 0;
                                    if (index == item.indexes[0]) {
                                        obj.attrs.rowSpan = item.indexes.length;
                                    }
                                }
                            })
                            return obj;
                        },
                        width: 90,
                        align: 'center',
                    },
                    {
                        title: '订单结算总价',
                        dataIndex: 'totalPrice',
                        customRender: (value, row, index) => {
                            const obj = {
                                children: value,
                                attrs: {},
                            };
                            let result = this.findDuplicateIds(this.dataSource);
                            result.forEach(item => {
                                if (item.id == row.id) {
                                    obj.attrs.rowSpan = 0;
                                    if (index == item.indexes[0]) {
                                        obj.attrs.rowSpan = item.indexes.length;
                                    }
                                }
                            })
                            return obj;
                        },
                        width: 110,
                        align: 'center',
                    },
                    { title: '商品图片', dataIndex: 'imagePath', align: 'center', width: 100, scopedSlots: { customRender: 'imagePath' } },
                    { title: '商品名称', dataIndex: 'goodsName', align: 'center', width: 300, },
                    { title: '规格', dataIndex: 'goodsSpecs', align: 'center', width: 300, },
                    { title: '商品数量', dataIndex: 'quantity', align: 'center' },
                    { title: '结算价', dataIndex: 'price', align: 'center' },
                    { title: '子订单状态', dataIndex: 'sonStatusStr', align: 'center' },
                    { title: '发货状态', dataIndex: 'fhStatusStr', align: 'center' },
                    { title: '物流单号', dataIndex: 'expressCompany', align: 'center', scopedSlots: { customRender: 'expressCompany' } },
                    { title: '发货时间', dataIndex: 'expressTime', align: 'center', scopedSlots: { customRender: 'expressTime' } },
                    { title: '操作', width: 120, align: 'center', fixed: "right", scopedSlots: { customRender: 'action' } },
                ]
            }
            this.dateValue = ''
            // this.reset()
            this.getData();
        },
        // 下单时间
        dateChange(date, dateString) {
            this.dateValue = date;
            if (dateString[0]) {
                this.queryData.orderDateMin = dateString[0] + " 00:00:00"
                this.queryData.orderDateMax = dateString[1] + " 23:59:59"
            } else {
                this.queryData.orderDateMin = ""
                this.queryData.orderDateMax = ""
            }
        },
        // 发货时间
        expressDateChange(date, dateString) {
            console.log(date, dateString, 'dateString')
            this.expressDateValue = date;
            if (dateString[0]) {
                this.queryData.expressDateMin = dateString[0] + " 00:00:00"
                this.queryData.expressDateMax = dateString[1] + " 23:59:59"
            } else {
                this.queryData.expressDateMin = ""
                this.queryData.expressDateMax = ""
            }
        },
        fileLink(url) {
            window.open(url, '_blank')
        },
        showModal(type, record) {
            console.log(record, 'record')
            if (type == 0) {
                this.getExpList()
                let data = JSON.parse(JSON.stringify(record))
                console.log(data, 'data')
                if (data.expressCode) {
                    // this.fhObj = data;
                    this.fhObj = {
                        type: data.expressCompanyType,
                        code: data.expressCode,
                        // ...data
                    }
                    // this.fhObj.type = data.expressCompanyType
                    // this.fhObj.code = data.expressCode
                } else {
                    this.fhObj = {}
                }
                this.fhOpen = true;
            } else {
                this.downOpen = true;
            }
            this.actionObj = record;
        },
        // 取消批量
        handlefhCancel() {
            this.fhAllOpen = false;
            this.expressCompanyId = undefined
            this.fileList = []
        },
        // 关闭弹窗
        showMsgChange() {
            this.showMsgSuccess = false;
            this.tipsMsg = ""
        },
        // 批量添加物流
        handlefhAll() {
            if (!this.expressCompanyId) {
                this.$message.error('请选择物流公司！')
                return false;
            }
            if (!this.fileObj) {
                this.$message.error('请上传文件！')
                return false;
            }
            let formData = new FormData();
            formData.append('file', this.fileObj.file.originFileObj);
            formData.append('expressCompanyId', this.expressCompanyId);

            request(process.env.VUE_APP_API_BASE_URL + 'srm/orders/express/import', 'post', formData).then(res => {
                if (res.data.code == 200) {
                    if (res.data.data.failCount == 0) {
                        this.$success({
                            title: '提示',
                            content: (
                                '一共' + res.data.data.totalCount + '条物流信息，已全部上传完成'
                            ),
                        });
                        this.fhAllOpen = false;
                        this.getData()
                    } else {
                        this.$error({
                            title: '提示',
                            content: (                                
                                '导入失败！'+res.data.data.failReason
                            ),
                        });
                        this.fhAllOpen = false;
                    }
                    this.expressCompanyId = undefined
                    this.fileList = []
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        // 添加物流信息
        handlefh() {
            if (!this.fhObj.type) {
                this.$message.error('请选择物流公司！')
                return false;
            }
            if (!this.fhObj.code) {
                // code
                this.$message.error('请输入物流单号！')
                return false;
            }
            request(process.env.VUE_APP_API_BASE_URL + 'srm/orders/express/' + this.actionObj.ordersDetailsId, 'post', this.fhObj).then(res => {
                if (res.data.code == 200) {
                    this.$message.success('操作成功！')
                    this.fhOpen = false;
                    this.getData()
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        editStatus(data) {
            if (data.status == 2) {
                request(process.env.VUE_APP_API_BASE_URL + 'srm/goodsSpec/editStatus', 'post', {
                    id: data.id, reason: "", status: 1
                }).then(res => {
                    if (res.data.code == 200) {
                        this.$message.success('上架成功！')
                        this.getData()
                    } else {
                        this.$message.error(res.data.message)
                    }
                })
            } else {
                this.editId = data.id
                this.downOpen = true
            }

        },
        handleDown() {
            if (!this.reason) {
                this.$message.error('请输入取消原因！')
                return false;
            }
            request(process.env.VUE_APP_API_BASE_URL + 'srm/orders/close/' + this.actionObj.id + '/' + this.actionObj.ordersDetailsId, 'post', { reason: this.reason }).then(res => {
                if (res.data.code == 200) {
                    this.$message.success('订单取消成功！')
                    this.reason = ""
                    this.downOpen = false;
                    this.getData()
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },

        getUserInfo(id) {
            request(process.env.VUE_APP_API_BASE_URL + 'srm/qualification/detail/' + id, 'post', {}).then(res => {
                this.userInfo = res.data.data;
                this.formData = this.userInfo.qualificationFileList[0]
                this.isModalVisible = true;
            })
        },
        handleCancel() {
            this.isModalVisible = false; // 取消操作
        },
        submit(data) {
            // 100 公司资质 200 品牌资质 300厂商资质
            data.type = 100
            if (this.userInfo.id) {
                data.id = this.userInfo.id
                data.companyId = this.userInfo.companyId
            }
            if (data.id) {
                request(process.env.VUE_APP_API_BASE_URL + 'srm/qualification/update', 'post', data).then(res => {
                    if (res.data.code == 200) {
                        this.getData();
                        this.$message.success('更新成功！')
                        this.isModalVisible = false;
                        this.formData = {}
                    }
                })
            } else {
                request(process.env.VUE_APP_API_BASE_URL + 'srm/qualification/save', 'post', data).then(res => {
                    if (res.data.code == 200) {
                        this.getData();
                        this.$message.success('保存成功！')
                        this.isModalVisible = false;
                        this.formData = {}
                    }
                })
            }
        },
        updateStatus(id, status) {
            request(process.env.VUE_APP_API_BASE_URL + 'srm/warehouse/updateStatus', 'post', {
                id, status
            }).then(res => {
                if (res.data.code == 200) {
                    this.getData();
                    this.$message.success('操作成功！')
                    this.isModalVisible = false;
                }
            })

        },
        onShowSizeChange(current, pageSize) {
            this.queryData.size = pageSize;
            this.getData()
        },
        handlePageChange(page) {
            this.queryData.page = page - 1;
            this.currentPage = page;
            this.getData()
        },
        hasPermission(permission) {
            return this.userPermissions.includes(permission);
        },
        reset() {
            if(this.fhStatus == 2 || this.fhStatus == 1) {
                this.queryData = {
                    page: 0,
                    size: 10,
                    fhStatus: this.queryData.fhStatus,
                    orderDetailStatus: this.queryData.orderDetailStatus
                }
            } else {
                this.queryData = {
                    page: 0,
                    size: 10,
                }
            }
            
            this.dateValue = ''
            this.expressDateValue = ''
            this.getData()
        },
        search() {
            this.queryData.page = 0;
            this.getData();
        },
        // 获取列表 
        getData() {
            this.tableLoading = true;
            request(process.env.VUE_APP_API_BASE_URL + 'srm/orders/list', 'post', this.queryData).then(res => {
                this.tableLoading = false;
                let data = res.data.data;
                if (res.data.code == 200) {
                    this.dataSource = data.data;
                    this.queryData.page = data.page;
                    this.queryData.size = data.size;
                    this.total = data.total;
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        tabPageChange(page) {
            this.queryData.page = page - 1;
            this.tabCurrent = page;
            this.getData()
        },
        exportTask() {
            this.$message.loading('处理中')
            let params = this.queryData;
            params.remarks = ''
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/goods/cake/exportBrand', 'post', params).then(res => {
                if (res.data.code == 200) {
                    this.$message.success('导出成功！')
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        // 格式化年月日
        formatDate(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            // const hours = date.getHours().toString().padStart(2, '0');
            // const minutes = date.getMinutes().toString().padStart(2, '0');
            // const seconds = date.getSeconds().toString().padStart(2, '0');
            // let result = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes;
            let result = year + '-' + month + '-' + day
            return result
        },
        formatDateMin(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const seconds = date.getSeconds().toString().padStart(2, '0');
            let result = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
            return result
        },
    }
}
</script>

<style lang="less" scoped>
.search {
    // margin-bottom: 54px;
}

.fold {
    width: calc(100% - 216px);
    display: inline-block
}

.operator {
    margin-bottom: 18px;
}

@media screen and (max-width: 900px) {
    .fold {
        width: 100%;
    }
}

::v-deep .page-content {
    padding: 0 !important;
}

.cre-btn {
    width: 100%;
    height: 64px;
    line-height: 64px;
    background: #EEECFE;
    border-radius: 4px;
    border: 1px dashed #5542F6;
    cursor: pointer;
    font-size: 16px;
    font-family: PingFangHK-Regular, PingFangHK;
    font-weight: 400;
    color: #5542F6;
}

.btn-box button {
    width: 106px;
    height: 36px;
    margin-left: 10px;
}


.page-box {
    display: flex;
    justify-content: right;
    padding-right: 46px;
    margin-top: 10px;
}
</style>